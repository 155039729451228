import { TinglysingCard } from './TinglysingCard';
import { TinglysingViewProps } from './TinglysingCard.types';

export function TinglysingView({ tinglysing }: TinglysingViewProps) {
  return (
    <>
      {Object.values(tinglysing).map((tinglysingItem, index) => {
        return (
          <TinglysingCard
            tinglysing={tinglysingItem}
            key={tinglysingItem.status.id}
          />
        );
      })}
    </>
  );
}
