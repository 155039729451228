import { useIsMutating, useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { uploadFile } from '../../../utils/files';
import { CrossIcon } from '../../icons/CrossIcon';
import { LoadingSpinner } from '../../icons/LoadingSpinner';
import { UploadToCloudIcon } from '../../icons/UploadToCloud';

/**
 * Drag and drop component
 */
type DragAndDropProps = {
  onDelete?: () => void;
  currentFile?: any;
  setCurrentFile: any;
};

type ErrorMessages = {
  [key: string]: string;
};

const ERROR_MESSAGES: ErrorMessages = {
  FILE_TOO_LARGE: 'file-too-large',
  TOO_MANY_FILES: 'too-many-files',
  FILE_INVALID_TYPE: 'file-invalid-type',
};

const MAX_SIZE = 20_000_000;

export function FileUpload({
  onDelete,
  currentFile,
  setCurrentFile,
}: DragAndDropProps) {
  const { t } = useFlowContext();
  const isUploading = useIsMutating(['add-document']) > 0;
  const loading = useIsMutating(['upload-file']) > 0;

  const { mutate } = useMutation({
    mutationKey: ['upload-file', currentFile],
    mutationFn: async (file: File) => {
      const { id, name } = await uploadFile(file);
      setCurrentFile && setCurrentFile({ file, id, name });
    },
    onError: (err: any) => {
      if (err?.response) {
        setErrorMsg({
          message:
            err?.response?.status === 413
              ? t('file-too-large', {
                  maxSize: `${MAX_SIZE / 1_000_000} MB`,
                })
              : err?.response?.statusText,
          code: err?.response?.status,
        });
      }
    },
  });

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const acceptedFile = acceptedFiles[0];
      mutate(acceptedFile);
    },
    [mutate],
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      maxFiles: 1,
      accept: {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
      },
      maxSize: MAX_SIZE,
    });

  const [errorMsg, setErrorMsg] = useState<
    | {
        message: string;
        code: string | number;
      }
    | undefined
  >(undefined);

  const onPaste = useCallback(
    async (e: any) => {
      const file = e.clipboardData.files[0];
      if (file) {
        mutate(file);
      }
    },
    [mutate],
  );

  useEffect(() => {
    window.addEventListener('paste', onPaste);
    return () => {
      window.removeEventListener('paste', onPaste);
    };
  }, [onPaste]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      const error = fileRejections[0].errors[0];
      const errorMessage = ERROR_MESSAGES[error.code];
      if (errorMessage) {
        setErrorMsg({ message: t(errorMessage), code: error.code });
      }
    } else {
      setErrorMsg(undefined);
    }
  }, [fileRejections, t]);

  function handleRemoveFile() {
    onDelete && onDelete();
    setCurrentFile(undefined);
    setErrorMsg(undefined);
  }

  return (
    <section className="container">
      <div className="gap-4" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="flex cursor-pointer justify-center h-52 gap-3 rounded-md border items-center border-dashed border-sea-20 px-7 py-5 text-sea-20">
          {isUploading || loading ? (
            <LoadingSpinner width={24} height={24} />
          ) : (
            <UploadToCloudIcon />
          )}
          {isDragActive ? (
            <span className="my-auto">{t('drop-file-here')}</span>
          ) : (
            <span className="my-auto">
              {t('upload-drag-and-drop-or-click')}
            </span>
          )}
        </div>
        <p className="pt-2 text-right text-xs italic">
          {t(`max-file-amount-1`)}
        </p>
      </div>
      <span>
        {currentFile && (
          <div className="flex items-center break-words gap-2">
            {t('uploaded-file', 'Fil lastet opp')}: {currentFile?.file?.name} -{' '}
            {currentFile?.file?.size} bytes
            <button type="button" onClick={handleRemoveFile}>
              <div className="text-negative-600">
                <span className="sr-only">
                  {t('delete-document-button', 'Slett document')}
                </span>
                <CrossIcon height={24} width={24} />
              </div>
            </button>
          </div>
        )}
      </span>
      {errorMsg && (
        <div className="text-negative-600 text-xs italic">
          {errorMsg.message}
        </div>
      )}
    </section>
  );
}
