import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { TaskProps } from '../../../types/TaskProps';

type Props = {
  heading?: string | ReactNode;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  width?: string;
  t: TaskProps['t'];
};

export function Modal({
  heading,
  children,
  isOpen,
  onClose,
  width = '',
  t,
}: Props) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="min-h-full h-full flex items-end justify-center text-center md:items-center md:align-middle md:p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`flex h-fit-content  ${
                    width !== '' ? width : 'w-5/6'
                  } transform flex-col overflow-hidden rounded-t-lg bg-white text-left align-middle shadow-xl transition-all md:rounded-lg`}
                >
                  <div className="flex items-center justify-between border-b-2 p-4 text-center">
                    <Dialog.Title as="h3" className="text-base">
                      {heading}
                    </Dialog.Title>
                    <button
                      className="focus:bg-yellow inline-block flex h-6 w-6 items-center justify-center "
                      type="button"
                      onClick={onClose}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 9 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.38016 8.58094L4.24016 5.72094L7.26016 8.74094L8.46016 7.56094L5.44016 4.54094L8.30016 1.68094L7.20016 0.580938L4.34016 3.44094L1.34016 0.460938L0.160156 1.64094L3.16016 4.64094L0.300156 7.50094L1.38016 8.58094Z"
                          fill="black"
                        />
                      </svg>
                      <span className="sr-only">{t('close', 'Lukk')}</span>
                    </button>
                  </div>
                  <div>{children}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
