import { TaskProps } from '../../types/TaskProps';
import { hasPermissionsSaveOrComplete } from '../tasks/ManuellBehandling/utils/taskPermissionsHelper';

const TaskType = {
  LAST_OPP_DOKUMENT: 'last-opp-dokument',
  GODKJENN_DOKUMENT: 'godkjenn-dokument',
  SIGNOFF: 'signoff',
  FIRE_OYNE: 'fire-oyne',
  SIGNER_AVTALE_DOKUMENTER: 'signer-avtale-dokumenter',
  ENDRE_UTBETALING: 'endre-utbetaling',
  SJEKK_SIGNERINGSSTATUS_MANUELT: 'sjekk-signeringsstatus-manuelt',
};

function getSignoffTask(task: TaskProps['task']) {
  const isRejection = task.context.isRejection;
  const title = isRejection ? 'creditRejection' : 'creditApproval';

  return {
    title,
    actionTitle: 'open',
    disabled: task.status === 'completed',
  };
}

export const taskLists = [
  {
    id: 'my-tasks',
    query: 'taskCategory=user-task&status=pending',
    title: 'Mine oppgaver',
    filter: hasPermissionsSaveOrComplete,
    map: (task: TaskProps['task'], t: TaskProps['t']) => {
      if (task.taskType === TaskType.LAST_OPP_DOKUMENT) {
        let title = `Last opp ${t(task.variables.document.documentType)}`;
        if (task.variables.document.documentType === 'other') {
          title = `Last opp ${t(task.variables.document.description)}`;
        }
        return {
          title: task?.context?.hasCoApplicant
            ? `${title} - ${t(task.variables.document.owner)}`
            : title,
          actionTitle: 'open',
          disabled: task.status === 'completed',
        };
      }
      if (task.taskType === TaskType.GODKJENN_DOKUMENT) {
        let title = `Kontroller ${t(task.variables.document.documentType)}`;
        if (task.variables.document.documentType === 'other') {
          title = `Kontroller ${t(task.variables.document.description)}`;
        }
        return {
          title: task?.context?.hasCoApplicant
            ? `${title} - ${t(task.variables.document.owner)}`
            : title,
          actionTitle: 'open',
          disabled: task.status === 'completed',
        };
      }
      if (task.taskType === TaskType.SIGNOFF) {
        return getSignoffTask(task);
      }

      return {
        title: task.taskType,
        actionTitle: 'open',
        disabled: task.status === 'completed',
      };
    },
  },
  {
    id: 'other-user-tasks',
    query: 'status=pending&taskCategory=user-task',
    title: 'Andre brukeres oppgaver',
    filter: (task: TaskProps['task']) => !hasPermissionsSaveOrComplete(task),
    map: (task: TaskProps['task'], t: TaskProps['t']) => {
      if (task.taskType === TaskType.SIGNER_AVTALE_DOKUMENTER) {
        let title = t(task.taskType);
        if (task.context.type === 'pantedokument') {
          title = `Signer ${t(task.context.type)}`;
        }
        return {
          title: task?.context?.hasCoApplicant
            ? `${title} - ${t(task.variables.role)}`
            : title,
          actionTitle: 'open',
          disabled: true,
        };
      }
      if (task.taskType === TaskType.SIGNOFF) {
        return getSignoffTask(task);
      }
      return {
        title: task.taskType,
        actionTitle: 'open',
        disabled: true,
      };
    },
  },
  {
    id: 'optional-tasks',
    title: 'Valgfrie oppgaver',
    query: 'status=pending&taskCategory=message-task',
    filter: (task: TaskProps['task']) =>
      [TaskType.ENDRE_UTBETALING, TaskType.SJEKK_SIGNERINGSSTATUS_MANUELT].includes(task.taskType),
  },
  {
    id: 'failed-tasks',
    query: 'status=failed',
    title: 'Feilede oppgaver',
  },
];
